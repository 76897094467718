<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="업무 수정">

        </CarrotTitle>
        <div class="board">
          <taskPopup v-if="mod.showApprovalLine" @close="mod.showApprovalLine=false"  @selected="mod.setStaff"></taskPopup>
          <taskCCPopup v-if="mod.showApprovalLineCC" @close="mod.showApprovalLineCC=false"  @selected="mod.setStaffCC"></taskCCPopup>
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 제목</th>
                                <td colspan="3">
                                    <input type="text" v-model.trim="mod.title" class="w-100per">
                                </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 업무처리자</th>
                              <td colspan="3">
                                <button class="btn-default ml-10 float-left" @click="mod.showApprovalLine=true">선택</button>
                                <span class="ml-10 float-left" v-for="s in mod.line_staff" :key="s.idx">{{s.ename}}({{s.kname}})
                                  <button class="btn-del" @click="mod.delLineStaff(s.num)">X</button>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th><span class="txt-red">*</span> 업무공유자</th>
                              <td colspan="3">
                                <button class="btn-default ml-10 float-left" @click="mod.showApprovalLineCC=true">선택</button>
                                <span class="ml-10 float-left" v-for="s in mod.cc_staff" :key="s.idx">{{s.ename}}({{s.kname}})
                                  <button class="btn-del" @click="mod.delCCStaff(s.num)">X</button>
                                </span>
                              </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 중요도</th>
                                <td>
                                  <select v-model="mod.priority" class="w-200px">
                                    <option value="낮음">낮음</option>
                                    <option value="보통">보통</option>
                                    <option value="높음">높음</option>
                                  </select>
                                </td>
                                <th><span class="txt-red">*</span> 진행상태</th>
                                <td>
                                  <select v-model="mod.progress_state" class="w-200px">
                                    <option value="업무요청">업무요청</option>
                                    <option value="진행중">진행중</option>
                                    <option value="지연">지연</option>
                                    <option value="보류">보류</option>
                                    <option value="완료">완료</option>
                                  </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 내용</th>
                                <td colspan="3">
                                    <CarrotEditor etype="managementplan" v-model="mod.contents" class="w-100per h-300px"></CarrotEditor>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부1</th>
                                <td colspan="3">
                                  <label v-if="mod.file1_name  && !mod.delete_file1" class="ml-10 float-left">
                                    <a v-if="mod.file1_name" :href="mod.file1_url" class="float-left" target="_blank">{{ mod.file1_name }}</a>
                                    <button @click="mod.fileCancel(1)" class="btn-default float-left ml-10">삭제</button>
                                  </label>
                                    <CarrotFileUploader v-if="!mod.file1_name || mod.delete_file1" ref="file1" class="float-left"></CarrotFileUploader>
                                </td>
                            </tr>
                            <tr>
                              <th>파일첨부2</th>
                              <td colspan="3">
                                <label v-if="mod.file2_name  && !mod.delete_file2" class="ml-10 float-left">
                                  <a v-if="mod.file2_name" :href="mod.file2_url" class="float-left" target="_blank">{{ mod.file2_name }}</a>
                                  <button @click="mod.fileCancel(2)" class="btn-default float-left ml-10">삭제</button>
                                </label>
                                <CarrotFileUploader v-if="!mod.file2_name || mod.delete_file2" ref="file2" class="float-left"></CarrotFileUploader>
                              </td>
                            </tr>
                            <tr>
                              <th>파일첨부3</th>
                              <td colspan="3">
                                <label v-if="mod.file3_name && !mod.delete_file3" class="ml-10 float-left">
                                  <a v-if="mod.file3_name" :href="mod.file3_url" class="float-left" target="_blank">{{ mod.file3_name }}</a>
                                  <button @click="mod.fileCancel(3)" class="btn-default float-left ml-10">삭제</button>
                                </label>
                                <CarrotFileUploader v-if="!mod.file3_name || mod.delete_file3" ref="file3" class="float-left"></CarrotFileUploader>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotEditor from '@/components/common/CarrotEditor.vue'
import Swal from 'sweetalert2'
import taskPopup from '@/components/popup/payment/taskPopup.vue'
import taskCCPopup from '@/components/popup/payment/taskCCPopup.vue'
export default {
    layout:"BusinessManagement",
    components: {
        CarrotEditor,
      taskPopup,
      taskCCPopup
    },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const route  = useRoute();
        const file1 = ref(null);
        const file2 = ref(null);
        const file3 = ref(null);
        const mod = reactive({
            title : "",
            ctype : "",
            complete_date : "",
            priority      : 2,
            contents      : "",
            progress_state   : "업무요청",
            showApprovalLine: false,
            showApprovalLineCC: false,
            line_staff : [],
            cc_staff : [],
            file1_name : "", file1_url : "",
            file2_name : "", file2_url : "",
            file3_name : "", file3_url : "",
            delete_file1 : false, delete_file2 : false, delete_file3 : false,

            doCancel: () => {
                router.go(-1);
            },
            delLineStaff: (idx) => {
              mod.line_staff.splice(idx, 1);
            },
            delCCStaff: (idx) => {
              mod.cc_staff.splice(idx, 1);
            },
            fileCancel: (idx) => {
              if(idx==1){
                mod.delete_file1 = true;
              }else if(idx==2) {
                mod.delete_file2 = true;
              }else if(idx==3) {
                mod.delete_file3 = true;
              }
              },

            doSearch: () => {
              let params = {
                idx : mod.idx
              };
              axios.get("/rest/task/taskView", { params: params }).then((res) => {
                if( res.data.err == 0 ){
                  mod.info = res.data;

                  mod.manager = mod.info.idx_hq;
                  mod.state   = mod.info.state;

                  mod.title   = mod.info.title;
                  mod.contents   = mod.info.contents;
                  mod.priority   = mod.info.priority;
                  mod.progress_state   = mod.info.progress_state;

                  mod.line_staff=mod.info.taskCC.receive;
                  mod.cc_staff=mod.info.taskCC.share;


                  mod.receive= mod.info.taskCC.receive;
                  mod.share= mod.info.taskCC.share;
                  mod.isAuth = mod.info.is_auth=='Y'?true:false;
                  mod.file1_name = mod.info.file1_name;
                  mod.file1_url  = mod.info.file1_url;
                  mod.file2_name = mod.info.file2_name;
                  mod.file2_url  = mod.info.file2_url;
                  mod.file3_name = mod.info.file3_name;
                  mod.file3_url  = mod.info.file3_url;
                  mod.isLoaded = true;
                } else {
                  if(res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
            },
            doSubmit: () => {
                var formData = new FormData();
                formData.append("idx", mod.idx);
                formData.append("title", mod.title);
                formData.append("priority"     , mod.priority);
                formData.append("progress_state"     , mod.progress_state);
                formData.append("contents"     , mod.contents);
                formData.append("line_staff", JSON.stringify(mod.line_staff));
                formData.append("cc_staff", JSON.stringify(mod.cc_staff));

                if(!mod.file1_name || mod.delete_file1){
                  if(file1.value.file.filedata!==null )
                    formData.append("nfile1[]", file1.value.file.filedata);
                }
                if(!mod.file2_name || mod.delete_file2) {
                  if (file2.value.file.filedata !== null)
                    formData.append("nfile2[]", file2.value.file.filedata);
                }
                if(!mod.file3_name || mod.delete_file3){
                  if(file3.value.file.filedata!==null)
                    formData.append("nfile3[]", file3.value.file.filedata);
                }

                formData.append("dchk1", mod.delete_file1==true?"Y":"N");
                formData.append("dchk2", mod.delete_file2==true?"Y":"N");
                formData.append("dchk3", mod.delete_file3==true?"Y":"N");

                if( !mod.title ){
                    console.log(mod);
                    Swal.fire({
                        title : "업무요청",
                        text  : "필수 항목을 입력해주세요."
                    });
                    return;
                }

                axios.post('/rest/task/taskMod', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"TaskManagement-TaskView-idx",
                            params : { 
                                idx:mod.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
            setStaff : (info) => {
              mod.showApprovalLine = false;
              mod.line_staff = [];
              for(let i=0;i<info.line_staff.length;i++) {
                  mod.line_staff.push(info.line_staff[i]);
              }
            },
            setStaffCC : (info) => {
              mod.showApprovalLineCC = false;
              mod.cc_staff = [];
              for(let i=0;i<info.line_staff.length;i++) {
                mod.cc_staff.push(info.line_staff[i]);
              }
            },
        });

        onMounted(() => {
            // Mounted
          mod.is_loaded = false;

          mod.idx = route.params.idx;
          if ( !mod.idx ) {
            router.go(-1);
          }
          mod.doSearch();
        });

        return {mod, file1, file2, file3};
    }
}
</script>

<style lang="scss" scoped>
</style>